import React, { useState } from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import InfoSection from '../components/InfoSection';
import {homeObjOne} from '../components/InfoSection/data.js';

function Informacion(id) {
  const [currentSubtitle,setSubtitle] = useState(homeObjOne);
  return (
    <div>
      <InfoSection setSubtitle = {(sm) => setSubtitle(sm)} {...currentSubtitle}/>
    </div>
     
  );
}

export default Informacion;
