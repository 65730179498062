import First from "../../images/Products/cebolla.jpg";
import Second from "../../images/Products/tomate.jpg";
import Third from "../../images/Products/ajo.jpg";

export default [
  {
    title: "",
    description: "",
    urls: First,
  },
  {
    title: "",
    description: "",
    urls: Second,
  },
  {
    title: "",
    description: "",
    urls: Third,
  },
];
 /*
  {
    title: "Seventh Slide",
    description: "This is the Seventh slider Image of our carousel",
    urls: "https://mdbootstrap.com/img/Photos/Slides/img%20(102).jpg",
  },
];
asi se puede hacer uno con ligas de internet
*/