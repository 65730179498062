export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'Desarrollo y Trabajo en Equipo',
  description:
    'Nuestra principal motivación es satisfacer las necesidades del consumidor y al mismo tiempo ofrecer seguridad' +
    ' y presencia en el mercado correcto a nuestros proveedores.',
  mision:
    'Nuestra misión es promover y comercializar productos con la más alta calidad generando valor a tanto a clientes como proveedores ' +
    'gracias a la innovación y competitividad que nos distingue.',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: require('../../images/equipo.jpg'),
  img2: require('../../images/compra.jpg'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};

