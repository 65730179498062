export const demo = {
  id: '',
  descripcion:
    '',
  color: '',
  sabor:'',
  olor:'',
  presentacion: '',
  almacenaje: '',
  seguridad:'',
  consumidor:'',
  vida:'',
  nota:'',
  imgStart: true,
  img: require('../../images/Products/ajo.jpg'),
  img_nf: require ('../../images/Products/ajo_nf.jpg'),
  dark: true,
  primary: true,
  darkText: false,
  lightText: false
};


  export const Ajo = {
    id: 'Ajo',
    descripcion:
      'Especie comestible de la planta del género y especie Allium sativum L., pertenecientes a la familia de las Liliáceas, empacado para su distribución una vez que ha sido secado al grado ' + 
      'en el pseudotallo (tallo), la película exterior de la cabeza y la película que rodea cada diente están secos.',
    color: 'Entre blanco y amarillo libre de partículas quemadas, tostadas y/o cocidas.',
    sabor:'El característico, picante y libre de sabores extraños.',
    olor:'El característico, picante y libre de olores extraños.',
    presentacion: '10kg en bolsa o saco de malla (desde 0.5 a 10 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'Los envases unitarios cuyas dimensiones y peso lo permitan se agrupan en cantidades adecuadas, en caja de cartón u otro material que proteja al producto.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por si solo y sin alergias al disulfuro de dialilo.',
    vida:' Buenas condiciones: hasta 3 meses. (18°C – 19°C) / Temperatura controlada: hasta 8 meses. (-2°C – 1°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/ajo.jpg'),
    img_nf: require ('../../images/Products/ajo_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  
  export const Ajonjoli = {
    id: 'Ajonjoli',
    descripcion:
      'Semilla obtenida de la especie Sesamum indicus L. de forma ovoide ligeramente achatada en el extremo superior; el color de la semilla depende de la variedad.',
    color: 'Tenue marrón claro característico de la especie, libre de partículas quemadas, tostadas y/o cocidas.',
    sabor:'No son aromáticas, así que después de un proceso adquieren un sabor suave y dulce parecido a la nuez o la almendra, libre de sabores extraños, putrefactos y/o rancidez.',
    olor:'El característico, libre de sabores extraños.',
    presentacion: '25kg en bolsa o saco de malla (desde 0.5 a 25 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'Se almacenan en tarimas recubiertas con catón, apilamiento máximo de 10 costales en un lugar con buena ventilación.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: prurito, eritema generalizado, el angioedema de la campanilla, una caída escarpada en la presión arterial o asma, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: de 6 a 8 meses (10°C – 25°C). / Temperatura controlada: hasta 12 meses (18°C). ',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/ajonjoli.jpg'),
    img_nf: require ('../../images/Products/ajonjoli_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const Amaranto = {
    id: 'Amaranto Endulzado ',
    descripcion:
      'Conjunto de granos andinos de la especie Amaranthus candatus l.',
    color: 'Conjunto de granos andinos de la especie Amaranthus candatus l.',
    sabor:'Característico, una combinación suave entre nuez y hierbas, al cocinarlo su sabor de acentúa.',
    olor:'Característico, una combinación suave entre nuez y hierbas, al cocinarlo su sabor de acentúa.',
    presentacion: '25kg en bolsa o saco de malla (desde 1 a 25 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón, con un apilamiento de 6 costales máximo (25kg por costal)',
    seguridad:'En tarimas con recubrimiento de cartón, con un apilamiento de 6 costales máximo (25kg por costal)',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: comezón y/o hinchazón en la piel, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: de 6 a 8 meses (10°C – 25°C) / Temperatura controlada: hasta 12 meses (18°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/amaranto.jpg'),
    img_nf: require ('../../images/Products/amaranto_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const Arroz = {
    id: 'Arroz',
    descripcion:
      'De la familia de las gramíneas, género Oryza, especie Oryza sativa a los cuales, se les quita la cáscara, el gérmen y la cutícula que constituye el salvado, obteniendo así el arroz.',
    color: 'De la familia de las gramíneas, género Oryza, especie Oryza sativa a los cuales, se les quita la cáscara, el gérmen y la cutícula que constituye el salvado, obteniendo así el arroz.',
    sabor:'Característico del arroz blanco.',
    olor:'Característico del arroz blanco.',
    presentacion: '25kg en bolsa o saco de malla (desde 1 a 25 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 6 costales de 25kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso presentarse después de ingerir: hinchazón del estómago, vómitos, diarrea y/o sarpullidos, suspender el consumo y consultar con su médico de confianza.',
    vida:' Buenas condiciones: hasta 12 meses (10°C – 25°C). / Temperatura controlada: hasta 17 meses (18°C). ',
    nota:' La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/arroz.jpg'),
    img_nf: require ('../../images/Products/arroz_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const Avena = {
    id: 'Avena',
    descripcion:
      'Producto obtenido de los granos de avena (avena sativa L.) privados de sus tegumentos, precocidos y laminados.',
    color: 'Entre crema y grisáseo',
    sabor:'Característico de la avena, casi imperceptible',
    olor:'Característico de la avena, casi imperceptible',
    presentacion: '20kg en bolsa o saco de malla (desde 1 a 20 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 6 costales de 20kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: piel manchada, ' +
                'irritada, con picazón, sarpullido o irritación de la piel en y en la boca, garganta irritada, secreción nasal o ' +
                'congestión nasal, ojos que pican, náusea, vómitos, diarrea, dolor de estómago, respiración dificultosa, anafilaxia,' +
                'dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 10 meses (10°C – 25°C). / Temperatura controlada: hasta 12 meses (18°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/avena.jpg'),
    img_nf: require ('../../images/Products/avena_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const Azucar = {
    id: 'Azúcar',
    descripcion:
      'Producto derivado de la caña de azúcar, constituido esencialmente por cristales sueltos de sacarosa, ' +
      'en una concentración mínima de 99.4% de polarización, éste tipo de azúcar se obtiene mediante proceso similar ' + 
      'utilizado para producir azúcar mascabado, aplicando variantes en las etapas de clarificación y centrifugación, ' +
      'con el fin de conseguir la calidad del producto deseada.',
    color: 'Marfil variando de claro.',
    sabor:'Dulce característico del producto.',
    olor:'Relacionado a dulce, casi imperceptible.',
    presentacion: '50kg en bolsa o saco de malla (desde 1 a 50 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 2 costales de 50kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: uticaria, '+
                'calambres en el estómago, vómitos, dificultad para respirar, hinchazón de los labios, la lengua o la garganta, ' +
                'y/o sibilancias, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 20 meses (20°C – 30°C) / Temperatura controlada: hasta 24 meses (28°C)',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/azucar.jpg'),
    img_nf: require ('../../images/Products/azucar_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };
  
  export const Bicarbonato = {
    id: 'Bicarbonato',
    descripcion:
      'Compuesto sólido cristalino de color blanco soluble en agua (NaHC03)',
    color: 'Blanco',
    sabor:'Ligero sabor alcalino, ligeramente salado',
    olor:'Ligero olor carbónico, casi imperceptible',
    presentacion: '25kg en bolsa o saco de malla (desde 1 a 25 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 6 costales de 25kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: comezón ' +
              'o ronchas, hinchazón del rostro o las manos, hinchazón u hormigueo en la boca o garganta, opresión en el pecho, ' +
              'dificultad para respirar, dolor de cabeza continuo, aumento rápido de peso, dolor de estómago severo, falta de ' + 
              'aliento, sudores fríos, y piel color azul, molestia estomacal que empeora o nuevos síntomas estomacales, inflamación ' +
              'en las manos, tobillos o pies, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:' Buenas condiciones: hasta 10 meses (15°C - 25°C) / Temperatura controlada: hasta 12 meses (20°C)',
    nota:' La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/bicarbonato.jpg'),
    img_nf: require ('../../images/Products/bicarbonato_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const Cacahuate = {
    id: 'Cacahuate',
    descripcion:
      'Arachis hypogaea, cacahuate o maní es una planta anual de la familia de las fabáceas (también conocidas como leguminosas), cuyos frutos de tipo legumbre contienen semillas apreciadas en la gastronomía.',
    color: 'Café claro.',
    sabor:'Característico del cacahuate, ligeramente amargo.',
    olor:'Ligeramente afrutado y oxidado.',
    presentacion: '20kg en bolsa o saco de malla (desde 1 a 20 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 6 costales de 20kg.',
    seguridad:'En tarimas con recubrimiento de cartón en apilamiento de máximo 6 costales de 20kg.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: reacciones en ' + 
                'la piel como urticaria, enrojecimiento o hinchazón, picazón u hormigueo en la boca y en la garganta o alrededor de ' +
                ' ellas, problemas digestivos como diarrea, cólicos estomacales, náuseas o vómitos, estrechamiento de la garganta, ' + 
                'falta de aire o sibilancias, goteo de la nariz dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones:  105 días temperatura menor 20°C / Temperatura controlada: hasta 12 meses (0°C – 10°C)',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/cacahuate.jpg'),
    img_nf: require ('../../images/Products/cacahuate_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const Cafe = {
    id: 'Café',
    descripcion:
      'Son un género que contiene en torno a cien taxones específicos e infraespecíficos aceptados,1 de los casi 400 descritos de plantas de la familia de las rubiáceas, nativas del sur de Asia y el África subtropical. Se cultivan extensamente por sus semillas que se emplean, molidas y tostadas, para la elaboración del café, una bebida estimulante; la popularidad de este hace que la importancia económica del cafeto sea extraordinaria, siendo uno de los productos vegetales más importantes del mercado global.',
    color: 'Castaño, pero puede variar de tonalidad dependiendo del método solicitado para su elaboración.',
    sabor:'Sabor característico del café, (frutal tostado).',
    olor:'Olor característico del café, (frutal tostado).',
    presentacion: '50kg en bolsa o saco de malla (desde 1 a 50 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 2 costales de 50kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir taquicardias, nerviosismo, ansiedad, dolores de cabeza continuados, insomnio, dolor de estómago –y a la larga reflujo gastroesofágico, vómitos y problemas de tensión dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: 12 meses (15°C – 25°C) bolsa cerrada. / Temperatura controlada: hasta 12 meses (15°C – 25°C) bolsa cerrada.',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/cafe.jpg'),
    img_nf: require ('../../images/Products/cafe_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const Canela = {
    id: 'Canela',
    descripcion:
      'El árbol de la canela, conocido como canelo 1, (Cinnamomum zeylanicum o Cinnamomum verum J.Presl) es un árbol de hoja perenne, de 10 a 15 metros de altura, procedente de Sri Lanka. Se aprovecha como especia su corteza interna, que se obtiene pelando y frotando las ramas.',
    color: 'Marron Grisáceo.',
    sabor:'Dulce leñoso, fino toque cítrico con un toque picante.',
    olor:'Olor característico de la canela.',
    presentacion: '10kg en bolsa o saco de malla (desde 1 a 10 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 6 costales de 10kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: dermatitis por contacto, alergia y enrojecimiento de la piel, vómitos, náuseas, diarrea, cólicos y disminución de azúcar en la sangre, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:' Buenas condiciones: 12 meses (15°C – 25°C) bolsa cerrada. / Temperatura controlada: hasta 12 meses (15°C – 25°C) bolsa cerrada.',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/canela.jpg'),
    img_nf: require ('../../images/Products/canela_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const ChileAncho = {
    id: 'Chile Ancho',
    descripcion:
      'Fruto de la planta cultivada Capsicum annuum perteneciente a la familia de las Solanáceas que ha pasado por un proceso de deshidratación.',
    color: 'Rojo Oscuro.',
    sabor:'Sabor afrutado con suave picor.',
    olor:'Aroma muy débil casi inperceptible.',
    presentacion: '500g en bolsa hasta pacas de 100kg.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 2 pacas de 100kg.',
    seguridad:'En tarimas con recubrimiento de cartón en apilamiento de máximo 2 pacas de 100kg.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: fuerte sudoración, fuerte ardor, vómitos, diarrea, náuseas, estornudos, secreción nasal, picazón en la garganta y en la piel, hinchazón de la lengua y los labios, pústulas y vesículas en la piel, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 12 meses (8°C – 10°C). / Temperatura controlada: hasta 12 meses (8°C – 10°C). ',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/chileancho.jpg'),
    img_nf: require ('../../images/Products/chileancho_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const ChileArbol = {
    id: 'Chile Arbol',
    descripcion:
      'Frutos pequeños, sin ninguna decoloración, cilíndricos, de forma prominentemente alargada y puntiaguda, se caracterizan por su elevada pungencia.',
    color: 'Rojo Intenso.',
    sabor:'Ahumado y ligeramente nogado, muy picoso.',
    olor:'Característico de un chile de árbol, penetrante y picante.',
    presentacion: '500gr en bolsa o saco de malla (desde 500gr a 100 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 2 pacas de 100kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: fuerte sudoración, fuerte ardor, vómitos, diarrea, náuseas, estornudos, secreción nasal, picazón en la garganta y en la piel, hinchazón de la lengua y los labios, pústulas y vesículas en la piel, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 12 meses (8°C – 10°C). / Temperatura controlada: hasta 12 meses (8°C – 10°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/chilearbol.jpg'),
    img_nf: require ('../../images/Products/chilearbol_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const ChileChipotle = {
    id: 'Chile Chipotle',
    descripcion:
      'El chile chipotle es un tipo de chile que se ha dejado madurar hasta hacerse chico, después de ahuma y aliña.',
    color: 'Rojo Oscuro.',
    sabor:'Ahumado.',
    olor:'Ligeramente picoso y ahumado.',
    presentacion: '500g en bolsa hasta pacas de 100kg.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 2 pacas de 100kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: fuerte sudoración, fuerte ardor, vómitos, diarrea, náuseas, estornudos, secreción nasal, picazón en la garganta y en la piel, hinchazón de la lengua y los labios, pústulas y vesículas en la piel, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'hasta 12 meses (8°C – 10°C)',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/chilechipotle.jpg'),
    img_nf: require ('../../images/Products/chilechipotle_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };



  export const ChileMulato = {
    id: 'Chile Mulato',
    descripcion:
      'El chile Mulato es un chile poblano seco de leve a medio, similar al Ancho, pero con un sabor ligeramente diferente. Ambos son verdes mientras crecen, pero mientras que el pimiento ancho es un pimiento poblano que madura hasta un rojo intenso, el pimiento mulato es un poblano que madura hasta un color marrón oscuro y luego se seca. Crece hasta unas 4 pulgadas de largo y 2 pulgadas de ancho, y se estrecha hacia la parte inferior.',
    color: 'Café negruzco.',
    sabor:'Sabor dulzon con notas de chocolate, en ocasiones picoso.',
    olor:'Achocolatados y herbáceos.',
    presentacion: '500g en bolsa hasta pacas de 100kg.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 2 pacas de 100kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: fuerte sudoración, fuerte ardor, vómitos, diarrea, náuseas, estornudos, secreción nasal, picazón en la garganta y en la piel, hinchazón de la lengua y los labios, pústulas y vesículas en la piel, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'hasta 12 meses (8°C – 10°C)',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/chilemulato.jpg'),
    img_nf: require ('../../images/Products/chilemulato_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const ChilePasilla = {
    id: 'Chile Pasilla',
    descripcion:
      'Es un fruto largo de cuerpo ondulado que termina en un ápice puntiagudo o chato; presenta de dos a tres lóculos. Su producción se destina casi exclusivamente para deshidratado con una pequeña cantidad que se consume en fresco.',
    color: 'Casi negro.',
    sabor:'Moderadamente picante.',
    olor:'Característico de chile pasilla seco.',
    presentacion: '500g en bolsa hasta pacas de 100kg.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 2 pacas de 100kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: fuerte sudoración, fuerte ardor, vómitos, diarrea, náuseas, estornudos, secreción nasal, picazón en la garganta y en la piel, hinchazón de la lengua y los labios, pústulas y vesículas en la piel, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'hasta 12 meses (8°C – 10°C)',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/chilepasilla.jpg'),
    img_nf: require ('../../images/Products/chilepasilla_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };


  export const Clavo = {
    id: 'Clavo',
    descripcion:
      'Producto obtenido a partir de los botones florales del clavero, especie Caryophyllus aromaticus L., los cuales son sometidos a un proceso de secado para ser envasados en recipientes.',
    color: 'Café claro.',
    sabor:'Café claro.',
    olor:'Aromático.',
    presentacion: '25kg en bolsa o saco de malla (desde 50g a 25 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 6 costales de 25kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: dermatitis o irritación en la piel, gastritis o alergia, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: de 8 meses a 10 meses (10°C – 21°C). / Temperatura controlada: hasta 12 months (5°C – 8°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/clavo.jpg'),
    img_nf: require ('../../images/Products/clavo_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };


  export const CocoRallado = {
    id: 'Coco Rallado',
    descripcion:
      'Coco (cocos nuci-fera L.) desecado, pelado, molido, secado y tamizado, se prepara en diversos tamaños.',
    color: 'Blanco.',
    sabor:'Característico del coco, dulce y liviano.',
    olor:'Característico del coco, dulce, fresco y aromático.',
    presentacion: '10kg en bolsa o saco de malla, se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 6 costales de 25kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir:  Vómitos, náuseas y diarrea, enrojecimiento de la piel y eczema, hinchazón de las membranas mucosas, labios y lengua, picazón y ardor en la boca y la garganta, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 8 meses (15°C – 18°C). / Temperatura controlada: hasta 12 meses (5°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/cocorallado.jpg'),
    img_nf: require ('../../images/Products/cocorallado_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const Comino = {
    id: 'Comino',
    descripcion:
      'Producto obtenido a partir de los frutos de la planta umbelífera de la especie Cominum cyminum L., los cuales deben estar sanos para después limpiarlos, secarlos (y su presentación lo requiere, molerlo).',
    color: 'Verde Olivo.',
    sabor:'Acre amargo.',
    olor:'Aromático fuerte.',
    presentacion: '25kg en bolsa o saco de malla (desde 1 a 25 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 6 costales de 25kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir:  anafilaxia, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 8 meses (< 20°C). / Buenas condiciones: hasta 8 meses (< 20°C). ',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/comino.jpg'),
    img_nf: require ('../../images/Products/comino_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const Nuez = {
    id: 'Nuez',
    descripcion:
      'Nuez pecanera con cáscara Carya illionensis (Wangenh) K. Koch, de la familia de las juglandáceas.',
    color: 'Pardo, rojizo oscuro.',
    sabor:'Ligeramente amargo.',
    olor:'Característico, fuerte y aromático.',
    presentacion: '20kg en bolsa o saco de malla (desde 100gr a 20 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 6 costales de 20kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: comezón e inflamación de la boca y de la garganta, brote de eczema, urticaria, dolores de estómago y vómitos, choque alérgico, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 6 meses (< 20°C). / Temperatura controlada: hasta 12 meses (14°C – 16°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/nuez.jpg'),
    img_nf: require ('../../images/Products/nuez_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const Jamaica = {
    id: 'Flor de Jamaica',
    descripcion:
      'Cubierta externa o primer verticilio floral, constituido por el conjunto de cinco a siete hojas modificadas fusionadas entre sí llamadas sépalos, previamente deshidratado para su comercialización y consumo humano.',
    color: 'Rojizo-Rosado claro.',
    sabor:'Acidulado, ligeramente astringente, no excesivamente agrio, ácido o amargo.',
    olor:'Característico de la Jamaica, aromático.',
    presentacion: '20kg en bolsa o saco de malla (desde 200g a 20 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 6 costales de 20kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: hemorragias, desmayos, reducción de los efectos antiinflamatorios, afectación en la concentración, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 6 meses (< 20°C). / Temperatura controlada: hasta 12 meses (4°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/jamaica.jpg'),
    img_nf: require ('../../images/Products/jamaica_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };


  export const Garbanzo = {
    id: 'Garbanzo',
    descripcion:
      'Producto obtenido a partir de granos secos de la planta papilonacea cicer arietinum, los cuales deben pasar por un tratamiento para asegurar su conservación.',
    color: 'Color Amarillento.',
    sabor:'Ligero parecido a las nueces.',
    olor:'Característico del garbanzo.',
    presentacion: '10kg en bolsa o saco de malla (desde 1 a 10 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 5 costales de 10kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir:   alergia oral, urticaria, angioedema, rinitis, crisis asmáticas, anafilaxia, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 12 meses (<25°C). / Temperatura controlada: hasta 18 meses (14°C – 16°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/garbanzo.jpg'),
    img_nf: require ('../../images/Products/garbanzo_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const Girasol = {
    id: 'Semilla de Girasol',
    descripcion:
      'Semillas comestibles de los aquenios de la planta llamada comúnmente girasol, se puede vender completo y se come lo de adentro de la semilla, desechando la cáscara, se pueden pasar por un proceso para agregarles sal.',
    color: 'Verde-Grisáceo.',
    sabor:'Característico de las semillas de girasol, un sabor casi neutro.',
    olor:'Característico de las semillas de girasol, casi imperceptible.',
    presentacion: '20kg en bolsa o saco de malla (desde 1 a 20 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 5 costales de 20kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: ronchas (urticaria), hinchazón de labios, vómitos, dolor abdominal o diarrea, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 6 meses (<25°C). / Temperatura controlada: hasta 12 meses (14°C – 16°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/girasol.jpg'),
    img_nf: require ('../../images/Products/girasol_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };


  export const Granola = {
    id: 'Granola',
    descripcion:
      'Alimento formado por nueces, copos de avena mezclados con miel, se ornea hasta que sea crujiente.',
    color: 'Café – crema.',
    sabor:'Característico de la granola, dulce y tostado.',
    olor:'Característico de la granola.',
    presentacion: '15kg en bolsa o saco de malla (desde 1 a 15 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 5 costales de 15kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: alteraciones dérmicas como enrojecimiento, urticaria, eccemas, picor intenso, sensación de quemazón, incluyen dolor y distensión abdominal, náuseas, vómitos o diarrea, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 6 meses (<25°C). / Temperatura controlada: hasta 12 meses (14°C – 16°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/granola.jpg'),
    img_nf: require ('../../images/Products/granola_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const Laurel = {
    id: 'Laurel',
    descripcion:
      'Árbol perenne perteneciente a la familia de las lauráceas, la que da su nombre, originario de la zona mediteránea, sus hojas son utilizadas para condimentar la comida.',
    color: 'Verde – Marrón.',
    sabor:'Fresco y dulce.',
    olor:'Aromático.',
    presentacion: '50kg en bolsa o saco de malla (desde 1 a 50 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 2 costales de 50kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: dermatitis de contacto, irritación en las mucosas digestivas, vómitos, ardor estomacal, asma, manchas en la piel, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 9 meses (<25°C). / Temperatura controlada: hasta 15 meses (10°C-15°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/laurel.jpg'),
    img_nf: require ('../../images/Products/laurel_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };

  export const MaizPalomero = {
    id: 'Maiz Palomero',
    descripcion:
      'Especie de gramínea anual originaria del centro de México, grano seleccionado y secado que se utiliza para hacer las conocidas palomitas de maíz.',
    color: 'Amarillo – Crema.',
    sabor:'Característico del maíz palomero.',
    olor:'Característico del maíz palomero.',
    presentacion: '500gr en bolsa o saco de malla (desde 500gr a 20 kg según los gustos del cliente) y finalmente se etiquetan de acuerdo con la normativa vigente.',
    almacenaje: 'En tarimas con recubrimiento de cartón en apilamiento de máximo 6 costales de 20kg.',
    seguridad:'No consumir el producto en caso de que se observe la presencia de hongos, insectos y/o materia extraña.',
    consumidor:'Cualquier persona capaz de comer alimento por sí solo, en caso de presentarse después de ingerir: dermatitis de contacto, erupción cutánea, eczema, secreción nasal, tos, sibilancias y obstrucción de vía aérea, malestar gastrointestinal, dejar de consumirlo inmediatamente y consultar con su médico de confianza.',
    vida:'Buenas condiciones: hasta 8 meses (<25°C). / Temperatura controlada: hasta 12 meses (13°C-14°C).',
    nota:'La FDA no ha evaluado las declaraciones relacionadas con los suplementos dietéticos y no están destinadas a diagnosticar, tratar, curar o prevenir ninguna enfermedad o condición de salud.',
    imgStart: true,
    img: require('../../images/Products/maizpalomero.jpg'),
    img_nf: require ('../../images/Products/maizpalomero_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };


  export const menu ={
    id: 'menu',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Menu',
    headline: 'menu',
    
    submenu : [Ajo, Ajonjoli, Amaranto, Arroz, Avena, Azucar, Bicarbonato, Cacahuate, Cafe, Canela, ChileAncho, ChileArbol, ChileChipotle, ChileMulato, ChilePasilla, Clavo, CocoRallado, Comino, Nuez, Jamaica, Garbanzo, Girasol, Granola, Laurel, MaizPalomero]
  
  };

  export const demo2 = {
    id: '',
    descripcion:
      '',
    color: '',
    sabor:'',
    olor:'',
    presentacion: '',
    almacenaje: '',
    seguridad:'',
    consumidor:'',
    vida:'',
    nota:'',
    imgStart: true,
    img: require('../../images/Products/ajo.jpg'),
    img_nf: require ('../../images/Products/ajo_nf.jpg'),
    dark: true,
    primary: true,
    darkText: false,
    lightText: false
  };