import React, { useState } from 'react';
import Slider from "../components/ImageSliderFull/Slider";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Card from '../components/Card';

function Home() {
  return (
    <React.Fragment>
      <Slider />
      <Card />
    </React.Fragment>
     
  );
}

export default Home;
