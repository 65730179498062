import React from 'react';
import { Button } from '../ButtonElements';
import {FaInfoCircle, FaSearch, FaCaretRight } from 'react-icons/fa';
import { GiFruitBowl, GiChecklist } from "react-icons/gi";
import { GoPrimitiveDot } from "react-icons/go";
import {
  InfoContainer,
  InfoWrapper,
  concepto,
  InfoRow,
  Head,
  Caracteristica,
  Detalle,
  Operacion,
  Menu,
  Nutricional,
  TextWrapper,
  TopLine,
  TopLineIcon,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  ImgNF
} from './InfoElements';

import { menu} from './data';
const InfoSection = ({
  id,
  descripcion,
  color,
  sabor,
  olor,
  presentacion,
  almacenaje,
  seguridad,
  consumidor,
  vida,
  nota,
  img,
  img_nf,
  dark,
  primary,
  imgStart,
  darkText,
  lightText,
  setSubtitle
}) => {
  console.log(primary);
  return (
    <>
      <InfoContainer  id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Head>
             <ImgWrap>
                  <Img src={img} />
                  <Heading><GiFruitBowl />   {id}</Heading>
                  <Subtitle darkText={darkText}>{descripcion}</Subtitle>
              </ImgWrap>
            </Head>
            <Caracteristica>
              <TextWrapper>
                <Heading><FaInfoCircle />  Características</Heading>
                <TopLine><FaCaretRight />Color</TopLine>
                <Subtitle darkText={darkText}>{color}</Subtitle>
                <TopLine><FaCaretRight />Sabor</TopLine>
                <Subtitle darkText={darkText}>{sabor}</Subtitle>
                <TopLine><FaCaretRight />Olor</TopLine>
                <Subtitle darkText={darkText}>{olor}</Subtitle>
              </TextWrapper>
            </Caracteristica>
            <Operacion>
              <TextWrapper>
                <Heading> <GiChecklist />   Especificaciones</Heading>
                <TopLine><FaCaretRight />Presentacion</TopLine>
                <Subtitle darkText={darkText}>{presentacion}</Subtitle>
                <TopLine><FaCaretRight />Almacenaje</TopLine>
                <Subtitle darkText={darkText}>{almacenaje}</Subtitle>
                <TopLine><FaCaretRight />Seguridad del Producto</TopLine>
                <Subtitle darkText={darkText}>{seguridad}</Subtitle>
              </TextWrapper>
            </Operacion>
            <Detalle>
              <TextWrapper>
              <Heading><FaSearch />  Obervaciones</Heading>
                <TopLine><FaCaretRight />Consumidores</TopLine>
                <Subtitle darkText={darkText}>{consumidor}</Subtitle>
                <TopLine><FaCaretRight />Vida Util</TopLine>
                <Subtitle darkText={darkText}>{vida}</Subtitle>
                <TopLine><FaCaretRight />Nota Legal</TopLine>
                <Subtitle darkText={darkText}>{nota}</Subtitle>
              </TextWrapper>
            </Detalle>
            <Nutricional>
                <ImgWrap>
                    <ImgNF src={img_nf}/>
                </ImgWrap>
            </Nutricional>
            
            <Menu>
              <TextWrapper>
                <Heading lightText={lightText}>{'Fruto Seco'}</Heading>
                {
                  menu.submenu.map(sm => (
                    <Subtitle darkText={darkText} type="button" onClick={() => setSubtitle(sm)}> 
                    <TopLineIcon><GoPrimitiveDot />  {sm.id}</TopLineIcon></Subtitle>
                  ))
                }
                <BtnWrap>
                </BtnWrap>
              </TextWrapper>
            </Menu>

          </InfoRow>
        </InfoWrapper>
      </InfoContainer>

      
    </>
  );
};

export default InfoSection;
