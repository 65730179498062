import styled from 'styled-components';

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? 'white' : 'white')};
  top: 10%;
  @media screen and (max-width: 1920px) {
    padding: 5px;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  padding: 40px;
  justify-content: center;
`;

export const InfoRow = styled.div`
background: ${({ lightBg }) => (lightBg ? 'white' : 'white')};
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-area: content;
  grid-template-columns:  200px 300px 300px;
  grid-template-rows:  450px 450px 350px;
  margin-bottom: 1em;
  grid-row-gap: 0px;
  column-gap: .0rem;
  row-gap: 6rem;
  justify-content: center;
  grid-template-areas: 
              'col5 col1 col1 col1 col1 col1 col1 col1 ' 
              'col5 col2 col2 col4 col4 col4 col4 col4 '
              'col5 col3 col3 col3 col3 col6 col6 col6 '
              'col5 col3 col3 col3 col3 col6 col6 col6 ';          
  }
`;


/*es el frame de la imagen*/
export const Head = styled.div`
background: ${({ lightBg }) => (lightBg ? 'white' : 'white')};
  margin-bottom: -100px;
  grid-area: content;
  grid-area: col1;
  text-align: justify;
  top: 10%;
`;

export const Caracteristica = styled.div`
background: ${({ lightBg }) => (lightBg ? 'white' : 'white')};
  margin-bottom: -50%;
  grid-area: col2;
  text-align: center;
  
`;

export const Operacion = styled.div`
background: ${({ lightBg }) => (lightBg ? 'white' : 'white')};
  margin-bottom: -100px;
  grid-area: col4;
  text-align: center;
`;

/*es el frame principal del producto*/
export const Detalle = styled.div`
background: ${({ lightBg }) => (lightBg ? 'white' : 'white')};
  margin-bottom: -30px;
  grid-area: col3;
  text-align: center;
   
`;


export const Nutricional = styled.div`
background: ${({ lightBg }) => (lightBg ? 'white' : 'white')};
  margin-bottom: -10px;
  grid-area: col6;
  text-align: justify;
`;


export const Menu = styled.div`
  background: ${({ lightBg }) => (lightBg ? 'white' : 'white')};
  margin-bottom: -50px;
  grid-area: col5;
  text-align: justify;
`;



export const InfoRowR = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? "'col2 col1'" : "'col1 col2'"};
  /* Must use '\' css class \'' */

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2' 'col3 col3' 'col4 col4'`};
  }
  `;
 
export const TextWrapper = styled.div`
  max-width: 1920px;
  padding-top: 0;
  padding-bottom: 60px;
  justify-content: flex-start;
`;

export const TopLine = styled.div`
  color: #800028;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const TopLineIcon = styled.div`
  color: #800028;
  font-size: 14px;
  line-height: 5px;
  font-weight: 150;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const concepto = styled.div`
  color: black;
  max-width: 1920px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  justify-content: flex-start;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  justify-content: flex-start;
  color: ${({ lightText }) => (lightText ? '#800028' : '#800028')};

  @media screen and (max-width: 1920px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 1920px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  justify-content: flex-start;
  color: ${({ darkText }) => (darkText ? '#800028' : '#800028')};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 1920
  px;
  height: 100%;
  margin-top: -3%;
  margin-right: 10%;
  margin-left: 9%;
  justify-content: flex-start;
`;

export const Img = styled.img`
  width: 350px;
  height: 350px;
  object-fit: contain;
  margin-top: 0;
  margin-right: 10%;
  margin-left: 30%;
  padding-right: 0;
  
`;

export const ImgNF = styled.img`
  width: 400px;
  height: 400px;
  object-fit: contain;
  padding-top: 5%;
  padding-right: 20%;
  padding-left: 0%;
`;
