import React, { useState } from 'react';
import Navbar from '../components/Navbar/Navbar.js';
import NavbarM from '../components/NavbarM/NavbarM.js';
import Footer from '../components/Footer';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';


function Container(props) {
  const [isOpen, setIsOpen] = useState(false);
 
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Navbar toggle={toggle} />
      <NavbarM toggle={toggle} />
      {props.children}
      <Footer /> 
    </>
     
  );
}

export default Container;
