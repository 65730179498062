import React, { useState } from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import InfoProd from '../components/InfoProduct';
import {Ajo, Ajonjoli, Amaranto, Arroz, Bicarbonato, Cacahuate, Cafe, Canela, ChileAncho, ChileArbol, ChileChipotle, ChileMulato, ChilePasilla, Clavo, CocoRallado, Comino, Nuez, Jamaica, Garbanzo, Girasol, Granola, Laurel, MaizPalomero} from '../components/InfoProduct/data.js';

function Verdura(id) {
  const [currentSubtitle,setSubtitle] = useState(Ajo);
  return (
    <div>
      <InfoProd setSubtitle = {(sm) => setSubtitle(sm)} {...currentSubtitle}/>
    </div>
     
  );
}

export default Verdura;
