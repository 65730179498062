export const CardNiument = {
    title: 'Confianza',
    description:
    'Creamos relaciones solidas con nuestros clientes y '+
    'proveedores, creyendo firmemente que el crecimiento '+
    'se debe generar juntos.',
    links: ''
  };

  export const CardViumark = {
    title: 'Desarrrollo',
    description:
    'Otorgamos herramientas que permiten el desarrollo '+
    'de todos los que participamos en la cadena de negocio, '+
    'permitiendo así que alcancemos las metas trazadas.',
    links: ''
  };

  
  export const CardIntelinmuebles = {
    title: 'Trato Directo',
    description:
    'Nuestra comunicación se realiza sin intermediarios, con la mayor cantidad '+
    'de canales de contacto para una mayor disponibilidad y un mejor servicio. ',
    links: ''
  };